import React from "react";
import Select from "../../../Select";
import { FilterMainComponentProps } from "../Filter";

const formTypeOptions = [
  { label: "All", value: "" },
  { label: "Opportunity", value: "OPPORTUNITY" },
  { label: "Presentation", value: "PRESENTATION" },
  { label: "Estimate", value: "ESTIMATE" },
  { label: "Sales Order", value: "SALES ORDER" },
  { label: "Invoice", value: "INVOICE" },
];

const FormTypeSelect = ({
  values,
  onChange,
  placeholder = "All",
}: FilterMainComponentProps) => (
  <Select
    value={values}
    // @ts-ignore
    options={formTypeOptions}
    // @ts-ignore
    onChange={(e: { value: string; label: string }) =>
      onChange(e?.value || null)
    }
    isClearable={values !== ""}
    placeholder={placeholder}
  />
);

export default FormTypeSelect;
